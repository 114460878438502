<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useNavigationGuard } from '@/shared/compositions/useNavigationGuard';
import { useTranslation } from '@/shared/compositions/useTranslation';
import { navigationList, type SubItem } from '@/shared/configs/navigation';
import getEnv from '@/shared/getEnv';
import { useTruePlayStore } from '@/shared/stores/truePlayStore';

const { cpaUrl } = getEnv();
const { t } = useTranslation('common');
const truePlayStore = useTruePlayStore();
const route = useRoute();
const { canNavigate } = useNavigationGuard();

const availableList = computed(() =>
  navigationList
    .filter((item) => {
      if ('route' in item) {
        return canNavigate(item.route);
      }
      return true;
    })
    .map((item) => {
      if ('items' in item) {
        return {
          ...item,
          items: item.items.filter((subItem) => {
            if ('route' in subItem) {
              return canNavigate(subItem.route);
            }
            return true;
          }),
        };
      }
      return item;
    })
    .filter((item) => !('items' in item) || !!item.items.length),
);

const groups = navigationList.filter((item) => 'items' in item) as SubItem[];

const activeItem = ref<string | null>(route.name as string);
const openedGroup = ref(
  groups.find((group) => {
    return group.items.some(
      (item) => 'route' in item && item.route === route.name,
    );
  })?.key || null,
);
const getItemName = (key: string) => t([`menu.${key}`, `page.${key}.header`]);
const getClassNavTab = (key: string) => [
  'nav__tab has-sub',
  {
    'widget-link': key === 'fairspin',
    'tournament-link': key === 'tournament',
    hidden: key === 'fairspin' && !truePlayStore.isIntegrated,
  },
];
const handleClick = (key: string) => {
  activeItem.value = key;
  openedGroup.value = null;
};
const handleGroupClick = (key: string) => {
  activeItem.value = key;
  openedGroup.value = key === openedGroup.value ? null : key;
};
</script>

<template>
  <nav class="nav">
    <ul>
      <li
        v-for="item in availableList"
        :key="item.key"
        :class="{
          active: [activeItem, openedGroup].includes(item.key),
          opened: item.key === openedGroup,
        }"
      >
        <a
          v-if="'link' in item"
          :class="getClassNavTab(item.key)"
          :href="`${cpaUrl}/${item.link}`"
          @click="() => handleClick(item.key)"
          target="_blank"
        >
          <span class="nav-label">
            {{ getItemName(item.key) }}
          </span>
          <span class="right-arrow" />
        </a>
        <RouterLink
          v-else-if="'route' in item"
          :class="getClassNavTab(item.key)"
          :to="{ name: item.route }"
          @click="() => handleClick(item.key)"
        >
          {{ getItemName(item.key) }}
        </RouterLink>
        <template v-else>
          <span
            :class="getClassNavTab(item.key)"
            @click="() => handleGroupClick(item.key)"
          >
            <span class="nav-label">{{ getItemName(item.key) }}</span>
            <span class="right-arrow" />
          </span>
          <div
            class="expand"
            :class="{ open: openedGroup === item.key }"
          >
            <div class="nav__sub-menu">
              <ul>
                <li
                  v-for="subItem in item.items"
                  :class="{
                    active: subItem.key === route.name,
                  }"
                  :key="subItem.key"
                >
                  <a
                    v-if="'link' in subItem"
                    :href="`${cpaUrl}/${subItem.link}`"
                    @click="activeItem = subItem.key"
                    target="_blank"
                  >
                    {{ getItemName(subItem.key) }}
                  </a>
                  <RouterLink
                    v-else
                    :to="{ name: subItem.route }"
                    @click="activeItem = subItem.key"
                  >
                    {{ getItemName(subItem.key) }}
                  </RouterLink>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
.expand {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.2s ease-out;
  height: auto;
  & > * {
    overflow: hidden;
    height: auto;
  }
  &.open {
    grid-template-rows: 1fr;
  }
}
</style>
