import { Route } from '@/shared/contracts/route';

type CommonNavItem = {
  key: string;
};
type LinkNavItem = CommonNavItem & {
  link: string;
};
type RouteNavItem = CommonNavItem & {
  route: Route;
};
export type SubItem = CommonNavItem & {
  items: (RouteNavItem | LinkNavItem)[];
};
type NavItem = LinkNavItem | RouteNavItem | SubItem;

export const navigationList: NavItem[] = [
  {
    key: 'dashboard',
    route: Route.dashboard,
  },
  {
    key: 'fairspin',
    route: Route.fairspin,
  },
  {
    key: 'offers',
    route: Route.offers,
  },
  {
    key: 'statistics',
    items: [
      {
        key: 'report',
        route: Route.report,
      },
      {
        key: 'conversions',
        route: Route.conversions,
      },
    ],
  },
  {
    key: 'finance',
    items: [
      {
        key: 'payment',
        route: Route.payment,
      },
      {
        key: 'payout',
        route: Route.payout,
      },
      {
        key: 'historyFinance',
        route: Route.historyFinance,
      },
      {
        key: 'balance',
        route: Route.balance,
      },
      {
        key: 'financeCurrency',
        route: Route.financeCurrency,
      },
    ],
  },
  {
    key: 'instruments',
    items: [
      {
        key: 'postbacks',
        route: Route.postbacks,
      },
      {
        key: 'historyPostback',
        route: Route.historyPostback,
      },
    ],
  },
  {
    key: 'myAccount',
    items: [
      {
        key: 'profile',
        route: Route.profile,
      },
      {
        key: 'security',
        route: Route.security,
      },
      {
        key: 'information',
        route: Route.information,
      },
    ],
  },
  // {
  //   key: 'tournament',
  //   link: 'en/tournament',
  // },
];
