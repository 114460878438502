import { useQuery } from '@tanstack/vue-query';
import { mapValues } from 'lodash';
import { computed, type MaybeRefOrGetter, toValue } from 'vue';
import { searchGoalEntries } from '@/shared/api/publisher';
import { useHttp } from '@/shared/compositions/useHttp';
import { useUser } from '@/shared/compositions/user/useUser';
import type { GoalEntryDto } from '@/shared/contracts/dto/GoalEntryDto';
import type { PaginatedResponse } from '@/shared/contracts/dto/PaginatedResponse';
import type { QueryConfig } from '@/shared/queries/lib/types';

export const useGoalEntriesQuery = (
  params: MaybeRefOrGetter<Record<string, any>> = {},
  config?: QueryConfig<PaginatedResponse<GoalEntryDto>>,
) => {
  const http = useHttp();
  const user = useUser();

  return useQuery({
    ...config,
    queryKey: computed(() => [
      'goalEntries/search',
      {
        locale: user.data.locale,
        ...toValue(params),
      },
    ]),
    queryFn: () => {
      return http
        .request(
          searchGoalEntries({
            ...mapValues(toValue(params), (val) =>
              Array.isArray(val) ? val.join(',') : val,
            ),
            _locale: user.data.locale,
          }),
        )
        .then((response) => response.data);
    },
  });
};
