<script setup lang="ts">
import { watch } from 'vue';
import { useRouter } from 'vue-router';
import KLoading from '@/shared/kit/KLoading.vue';
import { Route } from '@/shared/contracts/route';
import { useGoalEntriesQuery } from '@/shared/queries/useGoalEntriesQuery';
import { useBusinessStore } from '@/shared/stores/businessStore';

const router = useRouter();
const { data, isPending } = useGoalEntriesQuery();
const store = useBusinessStore();

watch(
  data,
  (_data) => {
    if (!_data) {
      return;
    }
    const { items } = _data;
    store.setHasGoals(!!items.length);
  },
  {
    immediate: true,
  },
);
watch(
  () => store.hasGoals,
  (hasGoals) => {
    if (hasGoals !== null && !hasGoals) {
      router.push({ name: Route.offers });
    }
  },
);
</script>

<template>
  <div
    v-if="isPending"
    class="global-loading"
  >
    <KLoading center />
  </div>
  <template v-else>
    <slot />
  </template>
</template>
