import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';

export const useBusinessStore = defineStore('business', () => {
  const hasGoals = ref<boolean | null>(null);
  function setHasGoals(value: boolean) {
    hasGoals.value = value;
  }

  return {
    hasGoals,
    setHasGoals,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBusinessStore, import.meta.hot));
}
