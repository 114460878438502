import type { AccountEntryDto } from '@/shared/contracts/dto/AccountEntryDto';
import type { ConversionDto } from '@/shared/contracts/dto/ConversionDto';
import type { GoalActionDto } from '@/shared/contracts/dto/GoalActionDto';
import type { GoalDto } from '@/shared/contracts/dto/GoalDto';
import type { GoalEntryDto } from '@/shared/contracts/dto/GoalEntryDto';
import type { LinkDto } from '@/shared/contracts/dto/LinkDto';
import type { OfferDto } from '@/shared/contracts/dto/OfferDto';
import type { OfferProposalDto } from '@/shared/contracts/dto/OfferProposalDto';
import type { PaginatedResponse } from '@/shared/contracts/dto/PaginatedResponse';
import type { PayoutDto } from '@/shared/contracts/dto/PayoutDto';
import type { PublisherContactTypeDto } from '@/shared/contracts/dto/PublisherContactTypeDto';
import type { PublisherPromoDto } from '@/shared/contracts/dto/PublisherPromoDto';
import type { PublisherReportDto } from '@/shared/contracts/dto/PublisherReportDto';
import type { CommonListRequestDto } from '@/shared/contracts/dto/RequestDto';
import { createHttpRequest } from '@/shared/utils/http';

export const editPublisher = (data: any) =>
  createHttpRequest({
    url: '/api/publisher/publisher.edit',
    method: 'POST',
    data,
  });

export const getReport = (data: any) =>
  createHttpRequest<PublisherReportDto>({
    url: '/api/publisher/report.get',
    method: 'POST',
    data,
  });

export const getContactTypes = (data: { _locale: string }) =>
  createHttpRequest<PublisherContactTypeDto[]>({
    url: '/api/publisher/publisher.getContactTypes',
    method: 'POST',
    data,
  });

export const searchPromo = (data: {
  count: number;
  page: number;
  _locale: string;
}) =>
  createHttpRequest<PublisherPromoDto>({
    url: '/api/publisher/promo.search',
    method: 'POST',
    data,
  });

export const getAccountEntries = (data: {
  _locale: string;
  page?: number;
  count?: number;
  created_at_from?: string;
  created_at_to?: string;
  sources?: string;
  accounts?: string;
}) =>
  createHttpRequest<PaginatedResponse<AccountEntryDto>>({
    url: '/api/publisher/publisherAccountEntries.search',
    method: 'POST',
    data: {
      fields:
        'publisher_account_entry.currency_ratio,publisher_account_entry_source.parameters,currency_ratio.rates',
      ...data,
    },
  });

export const convertCurrentCurrency = (data: { currency: string }) =>
  createHttpRequest({
    url: '/api/publisher/publisherAccount.convert',
    method: 'POST',
    data,
  });
export const getConversions = (data: { _locale: string }) =>
  createHttpRequest<PaginatedResponse<ConversionDto>>({
    url: '/api/publisher/conversions.search',
    method: 'POST',
    data: {
      fields:
        'conversion.adv_cid,conversion.currency_ratio,conversion.track,currency_ratio.rates,commission.contract,commission.sale_sum,commission.dynamic_cps_personal_rate,track.request,track.created_at,track_request.region,track_request.ip,track_request.device',
      ...data,
    },
  });

export const getNotification = (data: { _locale: string }) =>
  createHttpRequest<{
    id: string;
    appearance: string;
    content: string;
  } | null>({
    url: '/api/publisher/notification.get',
    method: 'POST',
    data,
  });

export const saveViewNotification = (data: { notification_id: string }) =>
  createHttpRequest({
    url: '/api/publisher/notification.saveView',
    method: 'POST',
    data,
  });
export const searchPayouts = (data: any = {}) =>
  createHttpRequest<PaginatedResponse<PayoutDto>>({
    url: '/api/publisher/publisherPayouts.search',
    method: 'POST',
    data: {
      fields:
        'publisher_payout.created_at,publisher_payout.sum_original,publisher_payout.payment_method_details,publisher_payout.fee,publisher_payout.from_dt',
      ...data,
    },
  });

export const sendPayout = (id: string) =>
  createHttpRequest({
    url: `/api/publisher/publisherPayouts/${id}/send`,
    method: 'GET',
  });

export const searchGoalEntries = (data: any = {}) =>
  createHttpRequest<PaginatedResponse<GoalEntryDto>>({
    url: `/api/publisher/goalEntries.search`,
    method: 'POST',
    data: {
      fields:
        'goal_entry.conversion_cap,goal_entry.offer,goal_entry.goal,goal_entry.access_granted,offer.advertiser,offer.locales,offer.default_link,goal.offer,offer.image,goal.description,goal.traffic_restrictions,goal_entry.status',
      ...data,
    },
  });

export const searchGoalActions = (
  data: CommonListRequestDto & {
    goal_ids?: string;
    for_postback?: boolean;
  },
) =>
  createHttpRequest<PaginatedResponse<GoalActionDto>>({
    url: '/api/publisher/goalActions.search',
    method: 'POST',
    data: {
      fields:
        'goal_action.goal,goal_action.commission_contract,goal.offer,action.offer,goal_action.rate',
      ...data,
    },
  });
export const searchOffers = (data: CommonListRequestDto) =>
  createHttpRequest<PaginatedResponse<OfferDto>>({
    url: '/api/publisher/offers.search',
    method: 'POST',
    data: {
      fields:
        'offer.advertiser,offer.description,offer.default_link,offer.locales,link.url,link.selfie_link,offer.image,offer.tds_domain,link.preview_url',
      ...data,
    },
  });

export const searchGoals = (
  data: CommonListRequestDto & { offer_ids?: string },
) =>
  createHttpRequest<PaginatedResponse<GoalDto>>({
    url: '/api/publisher/goals.search',
    method: 'POST',
    data: {
      fields: 'goal.offer,offer.advertiser,offer.default_link',
      ...data,
    },
  });

export const searchLinks = (data: CommonListRequestDto & Record<string, any>) =>
  createHttpRequest<PaginatedResponse<LinkDto>>({
    url: '/api/publisher/links.search',
    method: 'POST',
    data: {
      fields:
        'link.url,link.offer,link.default,link.selfie_link,link.locales,offer.advertiser,offer.locales,link.image,link.thumbnail,link.preview_url',
      ...data,
    },
  });
export const searchLinksLocales = (data: Record<string, any>) =>
  createHttpRequest<string[]>({
    url: '/api/publisher/links.getLocales',
    method: 'POST',
    data,
  });

export const searchOfferProposals = (data: {
  page?: number;
  count?: number;
  sort?: 'id' | 'offerType';
  order?: 'asc' | 'desc';
  offer_ids?: string;
  offerTypes?: 'dynamic' | 'static';
  fields?: string;
}) =>
  createHttpRequest<PaginatedResponse<OfferProposalDto>>({
    url: '/api/publisher/offerProposals.search',
    method: 'POST',
    data,
  });
export const requestOfferProposal = (data: { id: string | number }) =>
  createHttpRequest<1 | void>({
    url: '/api/publisher/publisherOfferProposals.request',
    method: 'POST',
    data,
  });
