import { useUser } from '@/shared/compositions/user/useUser';
import { Route } from '@/shared/contracts/route';
import { useBusinessStore } from '@/shared/stores/businessStore';
import { isJunket } from '@/shared/utils/user';

export const useNavigationGuard = () => {
  const { entity: userRaw } = useUser();
  const { hasGoals } = useBusinessStore();
  const canNavigate = (route: Route) => {
    if (!userRaw.value) {
      return false;
    }
    if (isJunket(userRaw.value)) {
      return ![Route.fairspin, Route.information].includes(route);
    }
    if (!hasGoals) {
      return [
        Route.offers,
        Route.offersPromo,
        Route.offersPersonal,
        Route.profile,
        Route.security,
        Route.information,
      ].includes(route);
    }

    return true;
  };

  return {
    canNavigate,
  };
};
